@media only screen and (max-width : 1024px) {
    #Nav {
        width: 100vw;
        transition: 200ms;
        overflow: hidden;
    }
    #Nav.hide {
        width: 0px;
    }

    #Main {
        width: 100vw;
        transition: 200ms;
        overflow: hidden;
    }
    #Main.hide {
        width: 0px;
        overflow: hidden;
        padding: 0px;
    }
}

#Main {
    height: calc(100vh - 56px);
    overflow-y: scroll;
}

.nav-dropdown {
    max-height: 0px;
    transition: 200ms ease-in-out;
    border-left-width: 3px;
}

.nav-dropdown.selected {
    max-height: 300px;
}