@tailwind base;
@tailwind components;
@tailwind utilities;

* { --cl-color-primary: #58A85F !important; }

.auth-bg {
    background-image: url('https://www.cythero.com/wp-content/uploads/2022/04/2-scaled-e1650635955504.jpg');
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width : 1024px) {
    .auth-bg-sm {
        background-image: url('https://www.cythero.com/wp-content/uploads/2022/04/2-scaled-e1650635955504.jpg');
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
    }

    .mh-custom-auth {
        min-height: 100vh;
    }
}

.select-list {
    background: none !important;
}


#date-modal-container {
    --width: 330px;
    --height: 479px;
    --background: #FFF;
}

#date-modal-container ion-toolbar {
    --background: #58A85F;
    --color: #FFF;
}

#date-modal-container IonContent {
    --background: #FFF;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F3F4F6;
}

*::-webkit-scrollbar-thumb {
    width: 6px;
    background: #58A85F;
}

#painting-session-modal-container {
    --width: 940px;
    --height: 800px;
    --max-height: 95vh;
    --background: #FFF;
}

#painting-session-modal-container ion-toolbar {
    --background: #58A85F;
    --color: #FFF;
}

#welding-session-modal-container {
    --box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --width: 900px;
    --height: 900px;
    --max-height: 95vh;
    --background: #F1F5F9;
}

#welding-session-modal-container ion-toolbar {
    --background: #58A85F;
    --color: #FFF;
}

@media only screen and (max-width : 1024px) {
    #painting-session-modal-container {
        --width: 100%;
        --height: 100%;
        --background: #FFF;
        --max-height: unset;
    }
    #painting-session-modal-container IonContent {
        margin: 5px;
    }
    
    #welding-session-modal-container {
        --width: 100%;
        --height: 100%;
        --background: #F1F5F9;
        --max-height: unset;
    }
    #welding-session-modal-container IonContent {
        margin: 5px;
    }
}

@media only screen and (max-width : 1024px) {
}

.pagebreak { page-break-before: always; }


#image_type-modal-container,
#settings-modal-container,
#grant-access-container,
#package_item-modal-container,
#session-info-container,
#create_package_item-modal-container {
    --background: #FFF;
    --box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

#image_type-modal-container ion-toolbar,
#settings-modal-container ion-toolbar,
#grant-access-container ion-toolbar,
#package_item-modal-container ion-toolbar,
#session-info-container ion-toolbar,
#create_package_item-modal-container ion-toolbar {
    --background: #58A85F;
    --color: #FFF;
}

#package_item-modal-container IonContent,
#settings-modal-container IonContent,
#grant-access-container IonContent,
#image_type-modal-container IonContent,
#session-info-container IonContent,
#create_package_item-modal-container IonContent {
    --background: #F3F4F6;
}

#image_type-modal-container { --width: 420px; --height: 600px; }
#settings-modal-container { --width: 420px; --height: 600px; }
#grant-access-container { --width: 420px; --height: 600px;  }
#package_item-modal-container { --width: 300px; --height: 200px; }
#session-info-container { --width: 600px; --height: 600px; }
#create_package_item-modal-container { --width: 330px; --height: 350px; }

@media only screen and (max-width : 600px) { #session-info-container { --width: 600px; --height: 100%; } }



.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 80%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1500px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}