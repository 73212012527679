.info {
    padding: 6px 10px;
    border-radius: .42rem;
    display: block;box-sizing: border-box;
    width: 100%;
    text-align: center;
}

.info.pending { background: #3880FF; color: #FFF; }
.info.warning { background: #FFC409; color: #222; }
.info.fulfilled{ background: #009587;color: #FFF; }
.info.rejected { background: #EB445A;color: #FFF; }